// Extrator.js

import React, { useState, useRef, useEffect } from "react";
import { MdArrowDropDownCircle } from "react-icons/md";
import { IoMdArrowDropupCircle } from "react-icons/io";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiError } from "react-icons/bi";
import { TbTemperature, TbNotes } from "react-icons/tb";

import "./Extrator.css";

const Extrator = ({ machineData }) => {
  const [expanded, setExpanded] = useState(false);
  const [buttonHeight, setButtonHeight] = useState(0);
  const cardRef = useRef(null);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    // Get the height of the card when it's expanded
    if (cardRef.current) {
      setButtonHeight(cardRef.current.clientHeight);
    }
  }, [expanded]);

  return (
    <div className="col-12 card-wrapper">
      <div className={`extrator-card ${expanded ? "expanded" : ""}`} ref={cardRef}>
      <div className="extrator-card-header">
        <span style={{ fontSize: "1.5rem" }}>{machineData.name}</span>
        <div className={`status ${machineData.isOn ? "on" : "off"}`}>
          {machineData.isOn ? "On" : "Off"}
        </div>
      </div>
      {expanded && (
        <div className="extrator-card-details">
          <div className="detail-item">
            <div className="icon">
              <AiOutlineClockCircle size={25} />
            </div>
            <div className="info">{machineData.timeWorking}</div>
          </div>
          <div className="detail-item">
            <div className="icon">
              <TbTemperature size={25} />
            </div>
            <div className="info">{machineData.temperature}</div>
          </div>
          <div className="detail-item">
            <div className="icon">
              <TbNotes size={25} />
            </div>
            <div className="info">{machineData.programName}</div>
          </div>
          <div className="detail-item">
            <div className="icon">
              <BiError size={25} />
            </div>
            <div
              className={`error ${!machineData.error.includes("CLEAR") ? "has-error" : ""}`}
            >
              {machineData.error ? machineData.error : "Clear"}
            </div>
          </div>
        </div>
      )}
    </div><div
      onClick={toggleExpand}
      className={`expand-button ${expanded ? "expanded" : ""}`}
      style={{ height: expanded ? buttonHeight + "px" : "5rem" }}
    >
        {!expanded ? <MdArrowDropDownCircle size={20} /> : <IoMdArrowDropupCircle size={20} />}
      </div>
      </div>
  );
};

export default Extrator;
