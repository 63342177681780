import React from "react";
import * as AiIcons from "react-icons/ai";
import * as GrIcons from "react-icons/gr";
import * as GiIcons from "react-icons/gi";
import * as RiIcons from "react-icons/ri";
import * as TbIcons from "react-icons/tb";

export const SidebarData = [
  {
    title: "Dashboard",
    icon: <GrIcons.GrOverview size={15} />,
    path: "/dashboard",
  },
   {
    title: "Extrator",
    icon: <GiIcons.GiBubblingBeam size={15} />,
    path: "/extrator",
  },
  {
    title: "Concentrador",
    icon: <AiIcons.AiOutlineFundView size={15} />,
    path: "/concentrador",
  },
  {
    title: "Spray Dryer",
    icon: <TbIcons.TbWindmill size={15} />,
    path: "/spraydryer",
  },
  {
    title: "Embaladora",
    icon: <AiIcons.AiOutlineFundView size={15} />,
    path: "/embaladora",
  }
];
