import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";

import Dashboard from "./Dashboard/Dashboard";

function AppRoutes() {
  const isAuthenticated = localStorage.getItem("isLoggedIn");

  return (
    <main className="container-fluid dashboard">
      <Switch>
        <Route exact path="/dashboard">
          <Dashboard />
        </Route>

        <Redirect to="/" />
      </Switch>
    </main>
  );
}

export default withRouter(AppRoutes);
