import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Sidebar.css";
import { SidebarData } from "./SidebarData";
import * as AiIcons from "react-icons/ai";
import ModalExit from "../Navbar/ModalExit";
import LogoTFS from "../Assets/_tfs_logo_vector";
import logo from "../Assets/_tfs_logo.png";

const Sidebar = ({
  collapsed,
  setCollapsed,
  setSelectedMachineViaDropDown,
}) => {
  const [option, setOption] = useState("DASHBOARD");
  const [isOpen, setIsopen] = useState(false);
  const sidebarCollapsed = localStorage.setItem("sidebar-collapsed", collapsed);
  const [isExpanded, setIsExpanded] = useState(sidebarCollapsed ? false : true);
  const [showModal, setShowModal] = useState(false);
  //console.log(setSelectedMachineViaDropDown);

  const handleToggler = () => {
    //console.log(isExpanded);
    setCollapsed(isExpanded);
    if (isExpanded) {
      setIsExpanded(false);
      localStorage.setItem("sidebar-collapsed", true);
      return;
    }
    setIsExpanded(true);
    localStorage.removeItem("sidebar-collapsed");
  };

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
    console.log(isOpen);
  };

  function handleSelect(value, path) {
    setOption(value);
    //console.log("SIDEBAR ---->", localStorage.getItem("selected-machine"));
  }

  const handleExitClick = () => {
    setShowModal(true);
  };

  const handleConfirmExit = () => {
    window.location.pathname = "/login";
    localStorage.clear();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (
      localStorage.getItem("selected-machine") === setSelectedMachineViaDropDown
    )
      setOption(setSelectedMachineViaDropDown);
    localStorage.setItem("selected-machine", option);
  }, [setSelectedMachineViaDropDown]);
  return (
    <div
      className={isExpanded ? "sidebar" : "sidebar collapsed"}
     
    >
      {/* <div className="sidebar-header">
        <RiIcons.RiFileShredFill className="sidebar-icon" onClick={handleToggler}/>
        <h1 className="sidebar-logo">logo</h1>

      </div> */}
      
     
      <div className="sidebar-menu">
        <div className="logo-toggler">
          <span className="logo-toggler-btn">{
            isExpanded? 
            <AiIcons.AiFillCaretLeft onClick={handleToggler} size={22}/>
        :
        <AiIcons.AiFillCaretRight onClick={handleToggler} size={22}/>}
            </span>
        </div>
        <div className="logo-center">
          <div>
            <Link to="/dashboard">
              {/* <img
              className="logo-xl"
                src={logo}
                //width={150}
                style={{ display: `${isExpanded ? "" : "none"}` }}
              ></img> */}
              <LogoTFS style={{width:"220px",  display: `${isExpanded ? "" : "none"}`}}/>
           
              <img
                src={logo}
                width={40}
                style={{
                  display: `${!isExpanded ? "" : "none"}`,
                  marginLeft: "0.3rem",
                }}
              ></img>
            </Link>
          </div>
        </div>
        <div className="sidebar-items">
          <ul className="sidebarList">
            {SidebarData.map((val, key) => {
              return (
                // <NavLink exact to={val.path} activeClassName="activeClicked">
                <li
                  key={key}
                  onClick={() => handleSelect(val.title, val.path)}
                  value={val.title}
                  className={"row" + (option === val.title ? " active" : " ")}
                >
                  <Link to={val.path} style={{ textDecoration: "none" }}>
                    <div className="col-12 row-item">
                      <div
                        className="sidebar-icon"
                        id="icon"
                        style={{
                          justifyContent: `${isExpanded ? "" : "left"}`,
                        }}
                      >
                        {val.icon}
                      </div>
                      <span
                        className="sidebar-text"
                        id="title"
                        style={{ display: `${isExpanded ? "" : "none"}` }}
                      >
                        {val.title}
                      </span>
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>

          <div className="sidebarList-exit">
            <div className="exit">
              <li
                onClick={() => {
                //   localStorage.setItem("isLoggedIn", false);
                //   window.location.pathname = "/";
                handleExitClick()
                }}
              >
                <AiIcons.AiOutlinePoweroff size={36} />
              </li>
            </div>
            <p
              style={{
                color: "#767676",
                display: `${isExpanded ? "" : "none"}`,
              }}
            >
              Logout
            </p>
          </div>
          {showModal && (
        <ModalExit onClose={handleCloseModal} onConfirm={handleConfirmExit} />
      )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
