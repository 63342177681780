import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Logo from "./Assets/_tfs_logo_vector";
import { URL } from "./config";
import "./Login.css";

const Login = ({ onLogin, user }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(true);

  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      if (mounted) setLoading(true);
    }, 1000);
    return () => {
      setMounted(false);
    };
  }, [mounted]);

  const handleLogin = async () => {
    try {
      const response = await fetch(`${URL}/tfs-api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("isLoggedIn", true);
        if (data.role.includes("admin"))
          localStorage.setItem("user", data.role);
        else {
          localStorage.setItem("user", data.role);
          localStorage.setItem("id", data.userId);
          localStorage.setItem("userId", data.id);
        }

        onLogin(true);

        if (data.role.includes("admin")) {
          history.push("/dashboard");
        }
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className={`login-page-container ${loading ? "" : "fade-in"}`}>
      <div className="card-login">
        <div className="card-login-header">
          <Logo style={{width:"200px"}}/>
        </div>
        <div className="card-login-body">
          <form className={`login-input-group ${loading ? "" : "fade-in"}`}>
            <label
              className="login-input-group-item"
              style={{ padding: "0.1rem 0rem", marginBottom: "0.2rem", textAlign:"left", color:"white"}}
            >
              username
            </label>
            <input
              className="login-input-group-item"
              type="text"
              placeholder=""
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                setErrorMessage("");
              }}
            />
            <label
              className="login-input-group-item"
              style={{ padding: "0.1rem 0rem", marginBottom: "0.2rem", textAlign:"left", color:"white"}}
            >
              password
            </label>
            <input
              className="login-input-group-item"
              type="password"
              placeholder=""
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrorMessage("");
              }}
              onKeyDown={handleKeyPress}
            />
          </form>
          <div className={`error-message-wrapper ${loading ? "" : "fade-in"}`}>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
          <div className="col-12 button-wrapper">

          <button
            className={`default-button ${loading ? "" : "fade-in"}`}
            onClick={handleLogin}
            disabled={!username && !password ? true : false}
            style={{textAlign:"center"}}
            >
            LOGIN
          </button>
              </div>
        </div>
      </div>
      <div className="login-input-group">
        <div
          className="footer-developer"
          style={{ fontSize: "small", marginTop: "3rem" }}
        >
          &copy;2023 -{" "}
          <span
            style={{ textDecoration: "none", color: "var(--secondary-color)" }}
          >
            developed by{" "}
          </span>
          <b style={{ color: "var(--primary-color)" }}>TECH</b> @{" "}
          <a
            href="http://www.morecolab.pt"
            style={{ textDecoration: "none", color: "var(--secondary-color)" }}
          >
            MORECOLAB.PT
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
