import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { URL } from "../config";

//----------MACHINES--------

import Extrator from "../Machines/Extrator/Extrator";
import Concentrador from "../Machines/Concentrador/Concentrador";
import SprayDryer from "../Machines/SprayDryer/SprayDryer";
import Embaladora from "../Machines/Embaladora/Embaladora";

const Dashboard = () => {
//   const extratorData = {
//     name: "Extrator",
//     isOn: true,
//     timeWorking: "2 hours",
//     temperature: 50,
//     programName: "Program A",
//     error: "CLEAR",
//   };
//   const concentradorData = {
//     name: "Concentrador",
//     isOn: true,
//     timeWorking: "2 hours",
//     pressure1: 37,
//     pressure2: 50,
//     volume: 50,
//     error: "CLEAR",
//   };

//   const sprayDryerData = {
//     name: "Spray Dryer",
//     isOn: true,
//     timeWorking: "2 hours",
//     inflow: 50,
//     temperatureIn: 50,
//     temperatureOut: 45,
//     suctionPower: 15000,
//     error: "CLEAR",
//   };

//   const embaladoraData = {
//     name: "Embaladora",
//     isOn: false,
//     timeWorking: "2 hours",
//     productionDay: "26",
//     productionYear: "1226",
//     error: "CLEAR",
//   };


const [extratorData, setExtratorData] = useState(null);
const [concentradorData, setConcentradorData] = useState(null);
const [sprayDryerData, setSprayDryerData] = useState(null);
const [embaladoraData, setEmbaladoraData] = useState(null);


  // Function to fetch data for a specific machine
  const fetchMachineData = (machineName, setData) => {
    // Make a fetch request to your API endpoint for the specific machine
    fetch(`${URL}/tfs-api/${machineName}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          // Assuming data is an array, update the state with the first item
          setData(data[0]);
        } else {
          // Handle the case when data is not an array
          console.error("Invalid data format for", machineName);
        }
      })
      .catch((error) => {
        console.error("Error fetching data for", machineName, error);
      });
  };
  

  const fetchAllData = () => {
    fetchMachineData("extrator", setExtratorData);
    fetchMachineData("concentrador", setConcentradorData);
    fetchMachineData("spraydryer", setSprayDryerData);
    fetchMachineData("embaladora", setEmbaladoraData);
  };
  // Use useEffect to fetch data for each machine and update it with a 30-second interval
  useEffect(() => {
   

    // Fetch data initially when the component mounts
    fetchAllData();

    // Set up an interval to fetch data every 30 seconds
    const interval = setInterval(fetchAllData, 30000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="dashboard-container">
      <div className="dashboard-wrapper">
        <div className="col-1">
          <div className="card-item-number">
            <h1 style={{ fontSize: "3rem" }}>1</h1>
          </div>
        </div>
        <div className="col-11 machine-wrapper">
          { extratorData !== null && <Extrator machineData={extratorData} />}
        </div>
      </div>

      <div className="dashboard-wrapper">
        <div className="col-1">
          <div className="card-item-number">
            <h1 style={{ fontSize: "3rem" }}>2</h1>
          </div>
        </div>
        <div className="col-11 machine-wrapper">
          { concentradorData !== null && <Concentrador machineData={concentradorData} />}
        </div>
      </div>

      <div className="dashboard-wrapper">
        <div className="col-1">
          <div className="card-item-number">
            <h1 style={{ fontSize: "3rem" }}>3</h1>
          </div>
        </div>
        <div className="col-11 machine-wrapper">
        { sprayDryerData !== null && <SprayDryer machineData={sprayDryerData} />}
        </div>
      </div>

      <div className="dashboard-wrapper">
        <div className="col-1">
          <div className="card-item-number" style={{background:embaladoraData !== null && embaladoraData.isOn ? "" : "lightgray"}}>
            <h1 style={{ fontSize: "3rem" }}>4</h1>
          </div>
        </div>
        <div className="col-11 machine-wrapper">
        { embaladoraData !== null &&<Embaladora machineData={embaladoraData} />}
        </div>
      </div>



    </div>
  );
};

export default Dashboard;
