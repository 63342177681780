import React, { useState, useEffect, useRef } from "react";
import { RiUserLine } from "react-icons/ri";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import ModalExit from "./ModalExit";
import TfsLeafs from "../Assets/_tfs_leaves";
import "./Navbar.css";

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dropdownRef = useRef(null);

  const history = useHistory();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };  

  const handleExitClick = () => {
    setShowModal(true);
  };

  const handleConfirmExit = () => {
    window.location.pathname = "/login";
    localStorage.clear();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <TfsLeafs/>
      {/* {localStorage.getItem('user').includes("admin") ? <WeblandHorizontalWhite onClick={() => history.push("/dashboard")} /> : <WeblandHorizontalWhite /> } */}
      </div>
    
      <div className="navbar-user">
        <span style={{marginRight:"0.5rem", fontSize:"small"}}>Administrador</span> <RiUserLine size={24} className="user-icon" onClick={toggleDropdown} />
        {!isDropdownOpen ? (
          <AiOutlineDown size={14} className="user-icon-toggle" onClick={toggleDropdown} />
        ) : (
          <AiOutlineUp size={14} className="user-icon-toggle" onClick={toggleDropdown} />
        )}
        {isDropdownOpen && (
          <div className="user-dropdown active" ref={dropdownRef}>
            <ul>
                <div className="authentication-wrapper">
                    <span>Autenticado:</span>
                    <h2>Tree Flowers </h2>
                </div>
              <hr className="user-dropdown-divider" />              
              <li onClick={() => {history.push("/account"); toggleDropdown()}}>Conta</li>
              <li onClick={() => {history.push("/errors"); toggleDropdown()}}>Erros</li>
              <li onClick={() => {history.push("/settings"); toggleDropdown()}}>Definições</li>
              <hr className="user-dropdown-divider" />
              <li onClick={() => handleExitClick()}>Logout</li>
            </ul>
          </div>
        )}
      </div> 
      {showModal && (
        <ModalExit onClose={handleCloseModal} onConfirm={handleConfirmExit} />
      )}
    </nav>
  );
};

export default Navbar;
