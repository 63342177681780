import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap-grid.css";
import Navbar from "./Navbar/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import "./App.css";
import AppRoutes from "./AppRoutes";

import { BrowserRouter as Router } from "react-router-dom";
import Login from "./Login";

function App() {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 750px)").matches
  );
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("isLoggedIn"));

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 750px)");
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addListener(handleResize);
    return () => mediaQuery.removeListener(handleResize);
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <div className="App">
      <Router>
        {!isLoggedIn ? (
          <Login onLogin={handleLogin} />
        ) : (
          <>
            <div className="navbar">
              <Navbar />
            </div>
            <div className={`${isSidebarCollapsed ? "sidebar-collapsed" : ""}`}>
              <Sidebar setCollapsed={setIsSidebarCollapsed} />            
                </div>
                <div className={`${isSidebarCollapsed ? "container-collapsed" : "container-expanded"}`}>
                  <AppRoutes isSidebarCollapsed={isSidebarCollapsed} />
              </div>
          
          </>
        )}
      </Router>
    </div>
  );
}

export default App;
